import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import AuthCallback from "./pages/AuthCallback";

const App = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={Dashboard} />
        {/* <Route exact path="/" component={Dashboard} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/auth-callback" component={AuthCallback} />
      </Switch>
    </Router>
  );
};

export default App;
