import { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

import Error from "./pages/Error";
import Loading from "./components/Loading";

import {
  userAuthenticated,
  authorizeUser,
  haveTokens,
  removeCaches,
} from "./services/auth";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  const isAuthedUser = userAuthenticated();
  const authLogin = !!localStorage.getItem("auth_login");
  const authorizationCode = localStorage.getItem("code");

  const firstLoginOrExpired =
    (!authLogin && !haveTokens()) ||
    (authLogin && haveTokens() && !isAuthedUser);

  useEffect(() => {
    if (error || success) {
      setLoading(false);
    }
    if (firstLoginOrExpired) {
      removeCaches();
      history.replace("/login");
    }

    authorizeUser(authorizationCode).then((res) => {
      if (!res.success) {
        if (res.status === 401 || res.status === 403) {
          setError("You are not authorized to view this page!");
          setLoading(false);
          return;
        }
        if (!res.authenticated) {
          setError(res.msg);
        }
      }
      setSuccess(true);
      setLoading(false);
    });
  }, [authorizationCode,error,firstLoginOrExpired,history,success]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Error authenticated={isAuthedUser} authError={error} />;
        }
        if (success) {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
