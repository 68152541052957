import distinctColors from "distinct-colors";

const __getRandomColors = (number, alpha) => {
  const palette = distinctColors({
    count: 100,
    hueMin: 0,
    hueMax: 360,
    chromaMin: 0,
    chromaMax: 85,
    lightMin: 30,
    lightMax: 95,
  });
  const colors = palette.map(
    (color) =>
      `rgba(${color._rgb[0]}, ${color._rgb[1]}, ${color._rgb[2]}, ${alpha})`
  );
  return colors;
};

const __parseDataByGraphType = (datasets, type) => {
  let response = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };
  let yaxistitle = "na";
  let xaxistitle = "na";
  if (type !== "area" && type !== "datatable") {
    datasets.forEach((dataset) => {
      const datasetKeys = Object.keys(dataset);
      xaxistitle = datasetKeys[0];
      yaxistitle = datasetKeys[1];
      datasetKeys.forEach((datasetKey, idx) => {
        if (datasetKey === "Count" || datasetKey === "Duration") {
          response.datasets[0].data.push(dataset[datasetKey]);
        } else {
          response.labels.push(dataset[datasetKey]);
        }
      });
    });
    const colors = __getRandomColors(datasets.length, 1);
    colors.forEach((color) => response.datasets[0].backgroundColor.push(color));
  } else if (type === "datatable") {
    response = [];
    datasets.forEach((dataset, datasetIdx) => {
      let resObj = {};
      const datasetKeys = Object.keys(dataset);
      datasetKeys.forEach((datasetKey, idx) => {
        resObj.id = datasetIdx;
        resObj[datasetKey] = dataset[datasetKey];
      });
      response.push(resObj);
    });
  } else {
    response.datasets = [];
    datasets.forEach((dataset, idx) => {
      Object.keys(dataset).forEach((k) => {
        const resDatasetElem = {
          label: "",
          data: [],
          options: {
            datalabels: {
              display: false,
              color: "#fff",
            },
          },
        };
        resDatasetElem.label = k;
        const myList = ["lightgreen", "#d06465"];
        resDatasetElem.backgroundColor = myList[idx];
        resDatasetElem.fill = true;
        const categories = dataset[k];
        categories.forEach((datasetObj) => {
          const datasetKeys = Object.keys(datasetObj);
          datasetKeys.forEach((datasetKey) => {
            if (datasetKey === "Count" || datasetKey === "Duration") {
              resDatasetElem.data.push(datasetObj[datasetKey]);
            } else {
              if (!response.labels.includes(datasetObj[datasetKey])) {
                response.labels.push(datasetObj[datasetKey]);
              }
            }
          });
        });
        response.datasets.push(resDatasetElem);
      });
    });
  }
  return { response, yaxistitle, xaxistitle };
};

export const getCurrentDate = () => {
  const datetime = new Date().toISOString();
  return datetime.split("T").join(" ").replace("Z", "");
};

export const getDuration = (dur) => {
  if (!dur) return null;
  const days = dur.split("P")[1].split("D")[0];
  return days === 1 ? `last_1_day` : `last_${days}_days`;
};

export const parseGraphDataFromJson = (jsonData, graphID) => {
  const chartDataArr = Object.values(
    Object.values(
      jsonData.response.filter((graph) => Object.keys(graph).includes(graphID))
    )[0]
  );
  const { title, data, type } = chartDataArr[0];
  const FullData = __parseDataByGraphType(data, type);
  const parsedData = FullData.response;
  const yaxistitle = FullData.yaxistitle;
  const xaxistitle = FullData.xaxistitle;
  return [parsedData, title, xaxistitle, yaxistitle];
};

export const getQueryParameter = (query) => {
  const search = window.location.search;
  if (search === "") return null;
  const queryParamsStr = search.slice(1);
  const queryParamsArr = queryParamsStr.split("&");
  const requestedParam = queryParamsArr.find((queryParam) =>
    queryParam.includes(query)
  );
  return requestedParam.split("=")[1];
};
