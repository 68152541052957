import ErrorMessage from "../components/ErrorMessage";
import NavBar from "../components/NavBar";

const Error = ({ authenticated, authError }) => {
  return (
    <>
      {authenticated && <NavBar />}
      {authError && <ErrorMessage error={authError} />}
    </>
  );
};

export default Error;
