import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const Dropdown = ({ defaultValue, title, style, options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue ?? "");

  const classes = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }))();

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormControl style={style} className={classes.formControl}>
      <InputLabel htmlFor="age-native-simple">{title}</InputLabel>
      <Select
        native
        value={selectedOption}
        onChange={handleChange}
        inputProps={{
          name: title,
          id: "age-native-simple",
        }}
      >
        <option aria-label="None" value="" />
        {options.map((opt) => (
          <option key={opt.label} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
