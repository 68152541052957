import { authedFetch } from "../services/auth";

export const getData = async (user, app, env, duration) => {
  const endpoint = `/dashboard?app=${app}&env=${env}&dur=${duration}${
    user !== "" ? `&user=${encodeURIComponent(user)} ` : ""
  }`;
  try {
    const res = await authedFetch(endpoint, {
      method: "GET",
    });
    if (!res.success) {
      return {
        success: false,
        status: res.status,
        msg: res.msg,
      };
    }
    return {
      success: true,
      data: res.data,
    };
  } catch (err) {
    return err;
  }
};
