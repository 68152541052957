import { Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";

const Loading = () => {
  const styles = {
    grid: {
      height: "45em",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: 20,
      width: 350,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    btn: { margin: "1em 0" },
  };
  const logout = localStorage.getItem("logout")
  if(logout==='yes'){
    // localStorage.removeItem("logout");
    return <Redirect to="/logout" />;

  }
  return (
    <Grid style={styles.grid}>
      <Paper style={styles.paper}>
        <Grid align="center">
          <h2>Loading</h2>
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Loading;
