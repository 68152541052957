import { Paper, Button } from "@material-ui/core";

import { getUsername, logout } from "../services/auth";

const NavBar = () => {
  const handleLogout = () => {
    logout();
  };

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 5em",
      height: "3em",
      marginBottom: "0.5em",
    },
    title: {
      fontSize: 22,
      marginBottom: "0.5em",
      fontWeight: "normal",
    },
    btn: {
      fontSize: 18,
      fontWeight: "normal",
    },
  };

  const username = getUsername();

  return (
    <Paper style={styles.container} elevation={2}>
      <h3 style={styles.title}>Welcome, {username}</h3>
      <Button variant="contained" color="secondary" onClick={handleLogout}>
        Logout
      </Button>
    </Paper>
  );
};

export default NavBar;
