import { Paper } from "@material-ui/core";

const EmptyCharts = () => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "7em",
      marginBottom: "0.5em",
      fontSize: 20,
    },
  };
  return (
    <Paper style={styles.container}>Please start filtering to view charts!</Paper>
  );
};

export default EmptyCharts;
