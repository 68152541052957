import React from "react";

import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Box from "@material-ui/core/Box";

const PieChart = ({ title, data, size }) => {
  return (
    <Box border={2} style={{ padding: "1em", width: size, height: size }}>
      <Pie
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          plugins: {
            legend: { display: true, position: "bottom" },
            datalabels: {
              display:false,
              color: "#fff",
              formatter: (value, context) => {
                const sum = context.dataset.data.reduce(
                  (total, element) => (total += element),
                  0
                );
                const percentage = ((value / sum) * 100).toFixed(2) + "%";
                return `${
                  context.chart.data.labels[context.dataIndex]
                } \n ${percentage}`;
              },
            },
            title: {
              display: true,
              font: {
                size: 16,
              },
              text: title,
            },
          },
        }}
      />
    </Box>
  );
};

export default PieChart;
