import PieChart from "./PieChart";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import Table from "./Table";
import Table2 from "./Table2";

const Charts = ({ encapsulatedData }) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <BarChart
          xAxisTitle={encapsulatedData.graph4Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph4Data.yAxisTitle}
          title={encapsulatedData.graph4Data.title}
          data={encapsulatedData.graph4Data.data}
          height="20%"
          width="50%"
        />
        <BarChart
          xAxisTitle={encapsulatedData.graph5Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph5Data.yAxisTitle}
          title={encapsulatedData.graph5Data.title}
          data={encapsulatedData.graph5Data.data}
          height="20%"
          width="50%"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <BarChart
          xAxisTitle={encapsulatedData.graph9Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph9Data.yAxisTitle}
          title={encapsulatedData.graph9Data.title}
          data={encapsulatedData.graph9Data.data}
          height="20%"
          width="33.3%"
        />
        <BarChart
          xAxisTitle={encapsulatedData.graph10Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph10Data.yAxisTitle}
          title={encapsulatedData.graph10Data.title}
          data={encapsulatedData.graph10Data.data}
          height="20%"
          width="33.3%"
        />
        <BarChart
          xAxisTitle={encapsulatedData.graph13Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph13Data.yAxisTitle}
          title={encapsulatedData.graph13Data.title}
          data={encapsulatedData.graph13Data.data}
          height="20%"
          width="33.3%"
        />
      </div>
      <div style={{ display: "none", justifyContent: "space-around"}}>
        <BarChart
          xAxisTitle={encapsulatedData.graph11Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph11Data.yAxisTitle}
          title={encapsulatedData.graph11Data.title}
          data={encapsulatedData.graph11Data.data}
          height="20%"
          width="50%"
        />
        <BarChart
          xAxisTitle={encapsulatedData.graph12Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph12Data.yAxisTitle}
          title={encapsulatedData.graph12Data.title}
          data={encapsulatedData.graph12Data.data}
          height="20%"
          width="50%"
        />
      </div>
      <div style={{ display: "flex" }}>
        <PieChart
          title={encapsulatedData.graph1Data.title}
          data={encapsulatedData.graph1Data.data}
          size="33.3%"
        />
        <PieChart
          title={encapsulatedData.graph2Data.title}
          data={encapsulatedData.graph2Data.data}
          size="33.3%"
        />
        <PieChart
          title={encapsulatedData.graph3Data.title}
          data={encapsulatedData.graph3Data.data}
          size="33.3%"
        />
      </div>
      <div>
        <LineChart
          xAxisTitle="Date"
          yAxisTitle="Count"
          title={encapsulatedData.graph6Data.title}
          data={encapsulatedData.graph6Data.data}
          height="25%"
          width="100%"
        />
      </div>
      <div>
        <BarChart
          xAxisTitle={encapsulatedData.graph7Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph7Data.yAxisTitle}
          title={encapsulatedData.graph7Data.title}
          data={encapsulatedData.graph7Data.data}
          height="20%"
          width="100%"
        />
      </div>
      <div>
        <BarChart
          xAxisTitle={encapsulatedData.graph8Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph8Data.yAxisTitle}
          title={encapsulatedData.graph8Data.title}
          data={encapsulatedData.graph8Data.data}
          height="20%"
          width="100%"
        />
      </div>
      <div style={{ display: "none"}}>
        <BarChart
          xAxisTitle={encapsulatedData.graph14Data.xAxisTitle}
          yAxisTitle={encapsulatedData.graph14Data.yAxisTitle}
          title={encapsulatedData.graph14Data.title}
          data={encapsulatedData.graph14Data.data}
          height="20%"
          width="100%"
        />
      </div>
      <div>
        <Table2
          title={encapsulatedData.table2Data.title}
          rows={encapsulatedData.table2Data.data}
        />
      </div>
      <div>
        <Table
          title={encapsulatedData.tableData.title}
          rows={encapsulatedData.tableData.data}
        />
      </div>
    </>
  );
};

export default Charts;
