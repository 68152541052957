
export const config = {
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  AUTH_SERVER: process.env.REACT_APP_AUTH_SERVER,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL,
  AUTH_LOGIN_PATH: process.env.REACT_APP_AUTH_LOGIN_PATH || "/authorize",
  AUTH_LOGOUT_PATH: process.env.REACT_APP_AUTH_LOGOUT_PATH || "/logout",
  AUTH_SOURCE: process.env.REACT_APP_AUTH_SOURCE || "azure",
  AUTH_TOKEN_TYPE: process.env.REACT_APP_AUTH_TOKEN_TYPE || "id_token",
  AUTHORIZATION_URL: process.env.REACT_APP_AUTHORIZATION_URL,
  API_URL: process.env.REACT_APP_API_URL,
  TOKEN_URL: process.env.REACT_APP_TOKEN_URL,
};

export const constants = {
  RESPONSE_TYPE: "code",
  STATE: "state-8600b31f-52d1-4dca-987c-386e3d8967e9",
  SCOPE: "openid",
};

const isAuthenticated = () => {
  return !!localStorage.getItem(config.AUTH_TOKEN_TYPE);
};

const isTokenExpired = () => {
  const accessToken = localStorage.getItem(config.AUTH_TOKEN_TYPE);
  const expirationTime = getValueFromToken(accessToken, "exp") * 1000; //to convert to milliseconds;
  const currentTime = new Date().getTime();
  return expirationTime < currentTime;
};

export const userAuthenticated = () => {
  return isAuthenticated() ? !isTokenExpired() : false;
};

export const userAuthorized = () => {
  return !!localStorage.getItem("authorized");
};

export const haveTokens = () => {
  const accessToken = localStorage.getItem("access_token");
  const idToken = localStorage.getItem("id_token");
  return accessToken && idToken;
};

export const getValueFromToken = (token, key) => {
  const base64Url = token.split(".")[1]; // getting payload
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const payload = JSON.parse(jsonPayload);
  return payload[key];
};

export const getUsername = () => {
  return localStorage.getItem("user");
};

export const getAuthorizationCodeFromURL = () => {
  return new URLSearchParams(window.location.search).get("code");
};

export const getAuthorizationCodeFromAuth = () => {
  const params = new URLSearchParams();
  params.set("client_id", config.AUTH_CLIENT_ID);
  params.set("response_type", constants.RESPONSE_TYPE);
  params.set("scope", constants.SCOPE);
  params.set("redirect_uri", config.AUTH_REDIRECT_URL);
  params.set("state", constants.STATE);
  const authLogin = `${
    config.AUTH_URL + config.AUTH_SERVER + config.AUTH_LOGIN_PATH
  }?${params.toString()}`;
  window.location.href = authLogin;
};

const getTokens = async (authCode) => {
  const URL = `${config.TOKEN_URL}/token`;

  try {
    const resStream = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: authCode,
        redirect_uri: config.AUTH_REDIRECT_URL,
        source: config.AUTH_SOURCE
      }),
    });
    if (!resStream.ok) {
      return {
        success: false,
        status: resStream.status,
        msg: resStream.statusText,
      };
    }
    const res = await resStream.json();
    localStorage.setItem("access_token", res.access_token);
    localStorage.setItem("id_token", res.id_token);
    return {
      success: true,
      status: resStream.status,
      msg: resStream.statusText,
    };
  } catch (err) {
    return {
      success: false,
      msg: "Error occurred with the authorization server",
    };
  }
};

export const removeCaches = async () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("auth_login");
  localStorage.removeItem("code");
};

export const logout = () => {
  const idToken = localStorage.getItem("id_token");
  const params = new URLSearchParams();
  params.set("post_logout_redirect_uri", config.AUTH_REDIRECT_URL);
  params.set("client_id", config.AUTH_CLIENT_ID);
  params.set("id_token_hint", idToken);
  const authLogoutURL = `${
    config.AUTH_URL + config.AUTH_SERVER + config.AUTH_LOGOUT_PATH
  }?${params.toString()}`;
  localStorage.setItem("logout", "yes");
  removeCaches();
  window.location.href = authLogoutURL;
};

export const authorizeUser = async (code) => {
  try {
    if (!haveTokens()) {
      if (!code)
        return {
          success: false,
          authenticated: false,
          msg: "Authorization code is not provided",
        };
      const res = await getTokens(code);
      if (!res.success) {
        return {
          success: false,
          status: res.status,
          msg: res.msg,
        };
      }
    }
    if (!userAuthenticated()) {
      return {
        success: false,
        authenticated: false,
        msg: "Your Last Session Has Been Expired Or Not Closed Properly , Please Logout & Login",
      };
    }
    const accessToken = localStorage.getItem(config.AUTH_TOKEN_TYPE);
    const resStream = await fetch(config.AUTHORIZATION_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!resStream.ok) {
      return {
        success: false,
        status: resStream.status,
        msg: resStream.statusText,
      };
    }
    const res = await resStream.json();
    localStorage.setItem("user",res.fullName != null ? res.fullName : res.userId);
    return {
      success: true,
      username: res.userId,
      msg: "User is authorized",
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      msg: "Error occurred",
    };
  }
};

export const authedFetch = async (endpoint, options) => {
  if (!userAuthenticated()) {
    return {
      success: false,
      authenticated: false,
      msg: "Session Expired",
    };
  }
  const accessToken = localStorage.getItem(config.AUTH_TOKEN_TYPE);
  try {
    const resStream = await fetch(config.API_URL + endpoint, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!resStream.ok) {
      return {
        success: false,
        status: resStream.status,
        msg: resStream.statusText,
      };
    }
    const data = await resStream.json();
    return {
      success: true,
      data,
    };
  } catch (err) {
    console.error(err);
    return {
      success: false,
      msg: err,
    };
  }
};
