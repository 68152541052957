const ErrorMessage = ({ error }) => {
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "10em",
      fontSize: 24,
    },
  };
  return <div style={styles.container}>{error}</div>;
};

export default ErrorMessage;
