import { useState, useEffect } from "react";

import { Paper, Button } from "@material-ui/core";
import Dropdown from "./Dropdown";
import options from "../options.json";
import { getData } from "../services/data";
import { getQueryParameter } from "../utils";

const Filter = ({ fetchedUsers, setFetchedUsers, onFilter, onLoading }) => {
  const [app, setApp] = useState(options.app_options[0].label);
  const [env, setEnv] = useState("prod");
  const [dur, setDur] = useState("P30D");
  const [user, setUser] = useState("");

  const parsedEnv = getQueryParameter("env");

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "7em",
      marginBottom: "0.5em",
    },
    dropdown: {
      marginLeft: "8em",
    },
    btn: {
      marginTop: "1em",
      marginLeft: "8em",
    },
  };

  const onDropdownChange = (type, value) => {
    switch (type) {
      case "App": {
        setApp(value);
        setFetchedUsers([]);
        setUser("");
        break;
      }
      case "Env": {
        setEnv(value);
        setFetchedUsers([]);
        setUser("");
        break;
      }
      case "Dur": {
        setDur(value);
        setFetchedUsers([]);
        setUser("");
        break;
      }
      case "User": {
        setUser(value);
        break;
      }
      default: {
      }
    }
  };

  const handleFilter = () => {
    const evaluatedEnv = parsedEnv ?? env;
    if (app && evaluatedEnv && dur) {
      onLoading(true);
      getData(user, app, evaluatedEnv, dur)
        .then((res) => {
          onFilter(res);
          onLoading(false);
        })
        .catch((err) => {
          onFilter(err, null);
          onLoading(false);
        });
    }
  };

  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <Paper style={styles.container} elevation={2}>
      {fetchedUsers.length > 0 && (
        <Dropdown
          style={styles.dropdown}
          title="User"
          options={fetchedUsers}
          defaultValue="No user selected"
          onChange={(value) => onDropdownChange("User", value)}
        />
      )}
      <Dropdown
        style={styles.dropdown}
        title="Application"
        options={options.app_options}
        defaultValue={app}
        onChange={(value) => onDropdownChange("App", value)}
      />
      {!parsedEnv && (
        <Dropdown
          style={styles.dropdown}
          title="Environment"
          options={options.env_options}
          defaultValue={env}
          onChange={(value) => onDropdownChange("Env", value)}
        />
      )}
      <Dropdown
        style={styles.dropdown}
        title="Interval"
        options={options.interval_options}
        defaultValue={dur}
        onChange={(value) => onDropdownChange("Dur", value)}
      />
      <Button
        style={styles.btn}
        variant="contained"
        color="primary"
        onClick={handleFilter}
      >
        Filter
      </Button>
    </Paper>
  );
};

export default Filter;
