import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../components/Loading";

import { getAuthorizationCodeFromURL } from "../services/auth";

const AuthCallback = () => {
  const [loading, setLoading] = useState(true);

  const logout = localStorage.getItem("logout") === "yes" ? true : false;

  useEffect(() => {
    const code = getAuthorizationCodeFromURL();
    if (code) {
      localStorage.setItem("code", code);
      localStorage.setItem("auth_login", true);
    }
    setLoading(false);
  }, []);

  return loading ? (
    <Loading />
  ) : logout ? (
    <Redirect to="/logout" />
  ) : (
    <Redirect to="/" />
  );
};

export default AuthCallback;
