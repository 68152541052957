import { useState } from "react";

import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import NavBar from "../components/NavBar";
import Filter from "../components/Filter";
import Charts from "../components/Charts";
import EmptyCharts from "../components/EmptyCharts";
// import Error from "../components/ErrorMessage";

import { parseGraphDataFromJson } from "../utils";

const Dashboard = () => {
  const defaultGraphData = {
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    },
    title: "",
  };

  const [encapsulatedData, setEncapsulatedData] = useState({
    graph1Data: defaultGraphData,
    graph2Data: defaultGraphData,
    graph3Data: defaultGraphData,
    graph4Data: defaultGraphData,
    graph5Data: defaultGraphData,
    graph6Data: defaultGraphData,
    graph7Data: defaultGraphData,
    graph8Data: defaultGraphData,
    graph9Data: defaultGraphData,
    graph10Data: defaultGraphData,
    graph13Data: defaultGraphData,
    graph11Data: defaultGraphData,
    graph12Data: defaultGraphData,
    graph14Data: defaultGraphData,
    tableData: { data: [], title: "" },
    table2Data: { data: [], title: "" },
  });

  const [fetchedUsers, setFetchedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rendered, setRendered] = useState(false);

  const onLoading = (state) => {
    setLoading(state);
  };

  const onFilter = (res) => {
    setError("");
    if (!res.success) {
      setRendered(true);
      setError(res.msg);
      return;
    }

    const resData = res.data;
    const [g1Data, g1Title, g1xAxisTitle, g1yAxisTitle] =
      parseGraphDataFromJson(resData, "graph1");
    const [g2Data, g2Title, g2xAxisTitle, g2yAxisTitle] =
      parseGraphDataFromJson(resData, "graph2");
    const [g3Data, g3Title, g3xAxisTitle, g3yAxisTitle] =
      parseGraphDataFromJson(resData, "graph3");
    const [g4Data, g4Title, g4xAxisTitle, g4yAxisTitle] =
      parseGraphDataFromJson(resData, "graph4");
    const [g5Data, g5Title, g5xAxisTitle, g5yAxisTitle] =
      parseGraphDataFromJson(resData, "graph5");
    const [g6Data, g6Title, g6xAxisTitle, g6yAxisTitle] =
      parseGraphDataFromJson(resData, "graph6");
    const [g7Data, g7Title, g7xAxisTitle, g7yAxisTitle] =
      parseGraphDataFromJson(resData, "graph7");
    const [g8Data, g8Title, g8xAxisTitle, g8yAxisTitle] =
      parseGraphDataFromJson(resData, "graph8");
    const [g9Data, g9Title, g9xAxisTitle, g9yAxisTitle] =
      parseGraphDataFromJson(resData, "graph9");
    const [g10Data, g10Title, g10xAxisTitle, g10yAxisTitle] =
      parseGraphDataFromJson(resData, "graph10");
    const [g13Data, g13Title, g13xAxisTitle, g13yAxisTitle] =
      parseGraphDataFromJson(resData, "graph13");
    const [g11Data, g11Title, g11xAxisTitle, g11yAxisTitle] =
      parseGraphDataFromJson(resData, "graph11");
    const [g12Data, g12Title, g12xAxisTitle, g12yAxisTitle] =
      parseGraphDataFromJson(resData, "graph12");
    const [g14Data, g14Title, g14xAxisTitle, g14yAxisTitle] =
      parseGraphDataFromJson(resData, "graph14");
    const [tablData, tablTitle, gt1xAxisTitle, gt1yAxisTitle] =
      parseGraphDataFromJson(resData, "datatable1");
    const [tabl2Data, tabl2Title, gt2xAxisTitle, gt2yAxisTitle] =
      parseGraphDataFromJson(resData, "datatable2");
    setEncapsulatedData({
      graph1Data: {
        data: g1Data,
        title: g1Title,
        xAxisTitle: g1xAxisTitle,
        yAxisTitle: g1yAxisTitle,
      },
      graph2Data: {
        data: g2Data,
        title: g2Title,
        xAxisTitle: g2xAxisTitle,
        yAxisTitle: g2yAxisTitle,
      },
      graph3Data: {
        data: g3Data,
        title: g3Title,
        xAxisTitle: g3xAxisTitle,
        yAxisTitle: g3yAxisTitle,
      },
      graph4Data: {
        data: g4Data,
        title: g4Title,
        xAxisTitle: g4xAxisTitle,
        yAxisTitle: g4yAxisTitle,
      },
      graph5Data: {
        data: g5Data,
        title: g5Title,
        xAxisTitle: g5xAxisTitle,
        yAxisTitle: g5yAxisTitle,
      },
      graph6Data: {
        data: g6Data,
        title: g6Title,
        xAxisTitle: g6xAxisTitle,
        yAxisTitle: g6yAxisTitle,
      },
      graph7Data: {
        data: g7Data,
        title: g7Title,
        xAxisTitle: g7xAxisTitle,
        yAxisTitle: g7yAxisTitle,
      },
      graph8Data: {
        data: g8Data,
        title: g8Title,
        xAxisTitle: g8xAxisTitle,
        yAxisTitle: g8yAxisTitle,
      },
      graph9Data: {
        data: g9Data,
        title: g9Title,
        xAxisTitle: g9xAxisTitle,
        yAxisTitle: g9yAxisTitle,
      },
      graph10Data: {
        data: g10Data,
        title: g10Title,
        xAxisTitle: g10xAxisTitle,
        yAxisTitle: g10yAxisTitle,
      },
      graph13Data: {
        data: g13Data,
        title: g13Title,
        xAxisTitle: g13xAxisTitle,
        yAxisTitle: g13yAxisTitle,
      },
      graph11Data: {
        data: g11Data,
        title: g11Title,
        xAxisTitle: g11xAxisTitle,
        yAxisTitle: g11yAxisTitle,
      },
      graph12Data: {
        data: g12Data,
        title: g12Title,
        xAxisTitle: g12xAxisTitle,
        yAxisTitle: g12yAxisTitle,
      },
      graph14Data: {
        data: g14Data,
        title: g14Title,
        xAxisTitle: g14xAxisTitle,
        yAxisTitle: g14yAxisTitle,
      },
      tableData: {
        data: tablData,
        title: tablTitle,
        xAxisTitle: gt1xAxisTitle,
        yAxisTitle: gt1yAxisTitle,
      },
      table2Data: {
        data: tabl2Data,
        title: tabl2Title,
        xAxisTitle: gt2xAxisTitle,
        yAxisTitle: gt2yAxisTitle,
      },
    });
    setFetchedUsers(g9Data?.labels.map(user => {
      return {
        label: user,
        value: user
      }
    }));
    setRendered(true);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      gridColumn: "span 3",
      gridRow: "span 1",
    },
    pieChart: {
      gridColumn: "span 1",
    },
  };
  if (error === "Session Expired") {
    return (
      <>
        <NavBar />
        <div
          style={{
            display: "flex",
            backgroundColor: "lightcyan",
            justifyContent: "center",
          }}
        >
          <p>
            Your last session is expired,please refresh the page or logout &
            login again
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <NavBar />
      <div style={styles.grid}>
        {error !== "" && <p>{error}</p>}
        <Filter fetchedUsers={fetchedUsers} setFetchedUsers={setFetchedUsers} onFilter={onFilter} onLoading={onLoading} />
        {loading ? (
          <Grid align="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : !rendered ? (
          <EmptyCharts />
        ) : (
          <Charts encapsulatedData={encapsulatedData} />
        )}
      </div>
    </>
  );
};

export default Dashboard;
