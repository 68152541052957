import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Paper, Button } from "@material-ui/core";

import { userAuthenticated } from "../services/auth";

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    if (userAuthenticated()) {
      history.replace("/");
    }
    localStorage.removeItem("logout");
    localStorage.removeItem("code");
  }, [history]);

  const handleLogin = () => {
    history.replace("/login");
  };

  const styles = {
    grid: {
      height: "45em",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: 20,
      width: 350,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    btn: { margin: "1em 0" },
  };

  return (
    <Grid style={styles.grid}>
      <Paper style={styles.paper}>
        <Grid align="center">
          <h2>You are Logged out</h2>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          style={styles.btn}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Paper>
    </Grid>
  );
};

export default Logout;
