import React from "react";

import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Box from "@material-ui/core/Box";

const BarChart = ({ title, xAxisTitle, yAxisTitle, width, height, data }) => {
  return (
    <Box border={2} style={{ padding: "1em", width: width, height: height }}>
      <Bar
        data={data}
        plugins={[ChartDataLabels]}
        options={{
          plugins: {
            legend: { display: false, position: "right" },
             datalabels: {
              display: false,
              color: "#fff",
            },
            title: {
              display: true,
              font: {
                size: 16,
              },
              text: title,
            },
          },
          responsive: true,
          scales: {
            yAxes: {
              title: {
                display: true,
                text: yAxisTitle,
                font: {
                  size: 18,
                },
              },
              ticks: {
                precision: 0,
              },
            },
            xAxes: {
              title: {
                display: true,
                text: xAxisTitle,
                font: {
                  size: 18,
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default BarChart;
