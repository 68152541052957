import { useEffect } from "react";

import {
  getAuthorizationCodeFromAuth,
  userAuthenticated,
} from "../services/auth";

const Login = () => {
  useEffect(() => {
    if (!userAuthenticated()) {
      getAuthorizationCodeFromAuth();
      return;
    }
  }, []);

  return null;
};

export default Login;
