import { forwardRef } from "react";

import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const columns = [
  {
    field: "Error",
    title: "Error",
    cellStyle: {
      width: "10%",
      maxWidth: "10%",
    },
    headerStyle: {
      width: "10%",
      maxWidth: "10%",
    },
  },
  {
    field: "Count",
    title: "Count",
    type: "numeric",
    cellStyle: {
      width: "10%",
      maxWidth: "10%",
    },
    headerStyle: {
      width: "10%",
      maxWidth: "10%",
    },
  },
  {
    field: "Detail",
    title: "Details",
    type: "numeric",
    cellStyle: {
      width: "80%",
      maxWidth: "80%",
    },
    headerStyle: {
      width: "80%",
      maxWidth: "80%",
    },
  },
  {
    field: "User",
    title: "User",
    cellStyle: {
      width: "80%",
      maxWidth: "80%",
    },
    headerStyle: {
      width: "80%",
      maxWidth: "80%",
    },
  },
];

const styles = {
  grid: {
    paddingTop: "2em",
    paddingBottom: "2em",
  },
  table: {
    maxWidth: "90%",
  },
};

const Table = ({ title, rows }) => {
  return (
    <Grid align="center" style={styles.grid}>
      <MaterialTable
        style={styles.table}
        icons={tableIcons}
        options={{
          headerStyle: {
            fontSize: 16,
          },
        }}
        columns={columns}
        data={rows}
        title={title}
      />
    </Grid>
  );
};

export default Table;
